<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        Subir Archivo
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-sm-4 pb-0">
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="6" class="py-0">
              <v-file-input
                v-model="file"
                label="Archivo a subir"
                accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
                prepend-icon="far fa-file-excel"
                :truncate-length="100"
                :rules="[rules.fileRequired]"
                :clearable="false"
                validate-on-blur
                outlined
                dense
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="tipo"
                label="Tipo de archivo"
                item-text="nombre"
                item-value="id"
                :items="tipos_archivo"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          nombre="Subir"
          icono="fas fa-upload"
          clase="ml-2"
          :loading="load"
          @action="cargar_archivo()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal de la vista generales -> ImportarArchivos
 */
import { mapState } from 'vuex'
import { read, utils } from 'xlsx'
import { tipos_archivos } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import moment from 'moment'

export default {
  data () {
    return {
      load: false,
      file: null,
      tipo: null,
      celdas: [],
      rules: {
        required: value => !!value || 'Campo requerido',
        fileRequired: value => {
          if (value) {
            const tipos_permitidos = ['.csv', '.xls', '.xlsx', '.ods']

            // busco en el array de tipos_archivos el tipo de archivo del archivo que subio
            const tipo_archivo = tipos_archivos.find(a => a.mime === value.type)

            if (tipo_archivo) {
            // si encuentro el tipo de archivo paso a validar que pertenezca a por lo menos un tipo de extension existente en el prop de tipos
              if (tipos_permitidos.find(a => a === tipo_archivo.extension)) {
                // la extension del tipo del archivo existe en los tipos validos
                return true
              }
              else {
                // subio un archivo con una extension no valida
                return 'Tipo de archivo no válido'
              }
            }
            else {
              // si no lo encuentra es porque subio un tipo de archivo que no esta registrado en el array tipos_archivos
              return 'Tipo de archivo no válido para el sistema'
            }
          }
          else {
            return 'Debe seleccionar un archivo'
          }
        }
      }
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    ...mapState('archivos', ['tipos_archivo']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      val || this.limpiar()
    }
  },
  methods: {
    async subir () {
      this.load = true
      await this.$store.dispatch('archivos/subir_archivo', {
        tipo: this.tipo,
        nombre: this.file.name,
        archivo: this.celdas
      })
        .then((res) => {
          this.dialog = false
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
        }).catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    cargar_archivo () {
      if (this.$refs.form.validate()) {
        const tipo_archivo = this.tipos_archivo.find(ta => ta.id == this.tipo)
        if (!tipo_archivo.columnas) {
          return this.$store.dispatch('show_snackbar', {
            text: 'El tipo de archivo seleccionado no tiene definida la cantidad de columnas',
            color: 'error'
          })
        }
        try {
          const reader = new FileReader()
          reader.onload = (e) => {
            const bytes = e.target.result
            const excel = read(bytes, { type: 'binary', cellDates: true })
            // obtengo el nombre de la primer hoja
            const hoja_nombre = excel.SheetNames[0]
            // obtengo la primer hoja
            const hoja = excel.Sheets[hoja_nombre]
            // obtengo la data de la primer hoja
            const data = utils.sheet_to_json(hoja, { header: 1 })
            // ciclo por cada row de la primer hoja (excluyendo los headers)
            this.celdas = []
            for (let i = 1; i < data.length; i++) {
              const row = data[i]
              // solo si la row tiene algun dato lo agrega al json
              if (row.length > 0) {
                let fila = {}
                for (let index = 0; index < tipo_archivo.columnas; index++) {
                  let columna = row[index]
                  // si la columna tiene formato de fecha la paso a dd/mm/aaaa, sino la dejo como está
                  try {
                    columna.getUTCDate()
                    columna = moment(columna).format('DD/MM/YYYY')
                  } catch (error) {
                    columna = columna ? columna.toString() : ''
                  }
                  fila[`col${index + 1}`] = columna
                }
                this.celdas.push(fila)
              }
            }
            // subo el archivo
            this.subir()
          }
          reader.readAsBinaryString(this.file)
        } catch (error) {
          this.celdas = []
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un error inesperado al convertir el archivo a JSON: ' + error,
            color: 'error',
            timeout: 4000
          })
        }
      }
    },
    limpiar () {
      this.$refs.form.resetValidation()
      this.file = null
      this.tipo = null
      this.celdas = []
    }
  }
}
</script>